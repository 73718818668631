import React from "react";

function PurchaseSuccess() {
  return (
    <div>
      <div class="page-header wf-section">
        <h1 class="h1-title">Purchase successful 🎉</h1>
        <p class="subheading-text">Enjoy your OSCEhub membership!</p>
        {/* <img src={Gif} loading="lazy" alt="" class="image-3" /> */}
      </div>
      <div class="section-browse wf-section">
        <div class="confirmation-page w-container">
          {/* <h2 class="heading-3">
            Get unlimited <span class="text-span-10">FREE</span> months
          </h2>
          <p class="subheading-text">
            Get a free month every time someone signs up to OSCEhub using your
            personal referral link (they also get 20% off)!
          </p>
          <div class="referral-div">
            <div class="text-block-6 referral-link">{link}</div>
            <a
              class="button-copy-link w-button copy-link"
              onClick={() => navigator.clipboard.writeText(link)}
            >
              Copy Link
            </a>
          </div> */}
          <a href="/dashboard" class="button dashboard w-button">
            Go to Dashboard
          </a>
        </div>
      </div>
    </div>
  );
}

export default PurchaseSuccess;
